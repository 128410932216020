#content_viewer .se-video-container{
  height: 100%;
}

#content_viewer {
  display: block !important
}


#content_viewer .se-image-container{
  height: 100% !important;
  width: 100% !important;
}

#content_viewer .se-image-container img{
  max-height: 100% !important;
  margin: auto;
}

#content_viewer .se-image-container figure{
  height: inherit !important;
}


#content_viewer .se-video-container>figure{
  padding-bottom:0 !important
}

#content_viewer iframe{
  border: none;
}