

/* colors */
$blue: #293b49;
$pink: #fdabaf;
$pink-light: #ffe0e6;
$green: #04cba0;
$green-dark: #01ac88;
$white: white;

.clip {
  margin: 0 auto;
  overflow: hidden;
  width: 9.5rem;
  height: 13rem;
  border-radius: 0 0 50% 50%;
}

.paper {
  position: relative;
  width: 9.2rem;
  height: 12.4rem;
  border: 0.3rem solid $blue;
  background-color: $white;
  border-radius: 0.8rem;
  
  &::before {
    content: "";
    position: absolute;
    top: -0.7rem;
    right: -0.7rem;
    width: 1.4rem;
    height: 1rem;
    background-color: $white;
    border-bottom: 0.3rem solid $blue;
    transform: rotate(45deg);
  }
}

.face {
  position: relative;
  margin-top: 2.3rem;
}

.eyes {
  position: absolute;
  top: 0;
  left: 2.4rem;
  width: 4.6rem;
  height: 0.8rem;
}

.eye {
  position: absolute;
  bottom: 0;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: $blue;
  animation-name: eye;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  
  &-left {
    left: 0;
  }

  &-right {
    right: 0;
  }
}

@keyframes eye {
  0% {
    height: 0.8rem;
  }
  50% {
    height: 0.8rem;
  }
  52% {
    height: 0.1rem;
  }
  54% {
    height: 0.8rem;
  }
  100% {
    height: 0.8rem;
  }
}

.rosyCheeks {
  position: absolute;
  top: 1.6rem;
  width: 1rem;
  height: 0.2rem;
  border-radius: 50%;
  background-color: $pink;
  
  &-left {
    left: 1.4rem;
  }

  &-right {
    right: 1.4rem;
  }
}

.mouth {
  position: absolute;
  top: 3.1rem;
  left: 50%;
  width: 1.6rem;
  height: 0.2rem;
  border-radius: 0.1rem;
  transform: translateX(-50%);
  background-color: $blue;
}

.text {
  margin-top: 5rem;
  font-weight: 300;
  color: $blue;
}

.button {
  margin-top: 4rem;
  padding: 1.2rem 3rem;
  color: $white;
  background-color: $green;
  
  &:hover {
    background-color: $green-dark;
  }
}

/* --------------------------
  Keyframes
--------------------------- */
@keyframes grow {
	0% {
		transform: scale(0);
	}
	30% {
		transform: scale(1.1);
	}
	60% {
		transform: scale(0.9);
	}
}
@keyframes turn {
	0% {
		transform: rotate(0) scale(0);
		opacity: 0;
	}
	60% {
		transform: rotate(375deg) scale(1.1);
	}
	80% {
		transform: rotate(355deg) scale(0.9);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}
@keyframes pulse {
	50% {
		transform: scale(1.4);
	}
}

/* --------------------------
  SVG Styles
--------------------------- */


.badge * {
	transform-origin: 50% 50%;
}

.outer, 
.inner,
.inline {
	animation: grow 1s ease-out backwards;
}

.inner {
	animation-delay: 0.1s;
}

.inline {
	animation-delay: 0.15s;
}

.star {
	animation: turn 1.1s .2s ease-out backwards;
}

.star circle {
	animation: pulse .7s 1.5s;
}

	.star circle:nth-of-type(2) {
		animation-delay: 1.6s;
	}
	.star circle:nth-of-type(3) {
		animation-delay: 1.7s;
	}
	.star circle:nth-of-type(4) {
		animation-delay: 1.8s;
	}
	.star circle:nth-of-type(5) {
		animation-delay: 1.9s;
	}