.quetionContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 5px 0px 5px 0px;
}
.leftSubContainer{
  min-height: 40px;
  min-width: 40px;
  background-color: #FAFAFA;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}
.contentContainer{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px ;
    background-color: #FAFAFA;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-left:10px;
    margin-right:10px;
    padding: 5px;
}

.contentContainerPlain{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40px ;
  margin-left:10px;
  margin-right:10px;
  padding: 5px;
}


.rightSubContainer{
    min-height: 40px;
    min-width: 40px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
.markedAnswer{
    background-color: #b9f6ca !important;
}