.dragableCell {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.drag{
    cursor: grab;
}
.helperContainerClass {
  width: auto;
  /* border: 1px solid #efefef;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2); */
  background-color: #F6FBFF;
  /* border-radius: 3px; */
}
.helperContainerClass:active {
  cursor: grabbing;
}
.helperContainerClass > td {
  padding: 5px;
  text-align: left;
  min-width: 200px;
}
.mr10{
  margin-right: 10px;
}